'use client';
import { createContext, useContext, useState } from 'react';

interface ContextGeneralType {
	showLogin: boolean;
	openMobileFilter: boolean;
	setShowLogin: (value: boolean) => void;
	handlerOpenMobileFilter: () => void;
}
const ContextGeneral = createContext<ContextGeneralType>({
	showLogin: false,
	openMobileFilter: false,
	setShowLogin: () => {},
	handlerOpenMobileFilter: () => {},
});

export const GeneralProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [showLogin, setShowLogin] = useState<boolean>(false);
	const [openMobileFilter, setOpenMobileFilter] = useState<boolean>(false);

	function handlerOpenMobileFilter() {
		setOpenMobileFilter(!openMobileFilter);
	}

	return (
		<ContextGeneral.Provider
			value={{
				openMobileFilter,
				showLogin,
				setShowLogin,
				handlerOpenMobileFilter,
			}}
		>
			{children}
		</ContextGeneral.Provider>
	);
};

export const useContextGeneral = () => useContext(ContextGeneral);

export default ContextGeneral;
